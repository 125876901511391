const bookContainer = document.querySelector(".book-container")
const bookHolder = document.querySelector(".book-holder")

bookHolder.addEventListener("mouseover", function () {
    document.documentElement.style.setProperty("--size", "35vh")
    bookContainer.style.zIndex = 3;
})

bookHolder.addEventListener("mouseout", function () {
    document.documentElement.style.setProperty("--size", "20vh")
    bookContainer.style.zIndex = 2;

})
